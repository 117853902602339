<template lang="pug">
div
  van-sticky(v-if='!isComponent')
    van-search(v-model='searchKey', placeholder='请输入甲方名称或订单编号' @search="onSearch" show-action shape="round")
      template(#action='')
        div(@click='onSearch') 搜索
    van-dropdown-menu
      van-dropdown-item(v-model='orderType', :options='options')
      van-dropdown-item(v-model='sortType', :options='option2')
  pull-refresh-list(
    ref="list"
    @update:list="list = $event"
    :getListMethod="getListData"
  )
    van-card.van-hairline--bottom(
      v-for="(order, index) of list"
      :key="index"
      :thumb="order.thumb"
      :tag="order.tag"
      @click="$router.push({name: 'orderdetail', query: {id: order.id}})"
      )
      template(#title)
        | 订单号：
        van-tag(type="danger" plain) {{order.code}}
      template(#desc)
        h3 甲方： {{`${order.fullname}`}}
        div 联系人： {{`${order.receipt}  ${order.tel}`}}
        //- div 销售金额： {{`￥${order.discount_price}`}}
      //- template(#footer)
        van-button(size='mini' @click="onLookContract($event,order,false)") 预览合同
        van-button(size='mini' @click="onLookContract($event,order,true)") 修改合同
        //- van-button(v-if="order.order_state != 'done' && order.order_state != 'canceled' &&  order.order_state != 'termination' " size='mini' @click="onLookContract($event,order,true)") 修改合同
  van-action-sheet(v-if="show" v-model='show', title='查看合同')
    contract-list(:value="contractData" :no_edit='editContract' @callback='onContract')
</template>


<script>
import { Sticky } from 'vant'
import { mapGetters } from 'vuex'
export default {
  props: ['isComponent', 'value', 'ofcCompanys'],
  components: {
    [Sticky.name]: Sticky,
  },
  data() {
    return {
      editContract: false,
      orderType: 'all',
      sortType: '',
      options: [{ text: '全部', value: 'all' }],
      option2: [
        { text: '排序', value: '' },
        { text: '更新时间', value: '-update_time' },
        { text: '下单日期', value: 'cycle_time' },
        { text: '交货日期', value: 'delivery_time' },
      ],
      list: [
        // {
        //   id: 1571,
        //   number:"1905201102426502317",
        //   company_name: '搭配家',
        //   receipt: '光明',
        //   tel: '13301130011',
        //   total_price: '100782',
        //   favour_price: '0.00',
        //   discount_price: '100782'
        // },
        // {
        //   id: 1571,
        //   number:"1905201102426502317",
        //   company_name: '搭配家',
        //   receipt: '光明',
        //   tel: '13301130011',
        //   total_price: '100782',
        //   favour_price: '0.00',
        //   discount_price: '100782'
        // }
      ],
      active: 0,
      searchKey: '',
      show: false,
      contractData: {},
      flowStatus: {}
    }
  },
  watch:{
    orderType(){
      this.$refs.list.refresh()
    },
    sortType(){
      this.$refs.list.refresh()
    }
  },
  computed: {
    ...mapGetters(['getFlow']),
    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    },
  },
  async mounted() {
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      }
    })
    let res = await this.getFlow('order')
    this.flowStatus = res.status
    if (res.status) {
      let keys = Object.keys(res.status)
      let status = keys.map((item) => {
        return { 
          value: item,
          text: res.status[item].alias
        }
      })
      this.options = this.options.concat(status)
    }
  },
  methods: {

    // 查看合同
    onLookContract(e,data,edit){
      e.stopPropagation()
      this.editContract = edit
      this.contractData = data
      // if(data.order_rel_contract && data.order_rel_contract.length > 0){
      // }else {
      //   this.$toast('没有合同信息')
      // }
      this.show = true
    },
    // 更新或者保存合同
    async onContract() {
      const tempId = this.contractData.id
      this.$toast('更新成功')
      try {
        let res = await this.$api.GetContract(tempId)
        this.$set(this.contractData, 'id_rel_contract_relation', res.data)
        this.show = false
      } catch (error) {
        this.$refs.list.refresh()
        this.$toast(this.$error(error))
      }
    },
    onSearch() {
      this.$refs.list.refresh()
    },
    async getListData(params) {
      try {
        let stId = (this.$store.state.userInfo.sell_users || {}).st_id_poi_company_stores || 0
        let where = {
          seller_poi_sell_users: this.sellerId,
          st_id_poi_company_stores: {
            $in: [0]
          },
        }
        if(stId != 0) {
          // 后台添加的数据没有st_id_poi_company_stores
          where.st_id_poi_company_stores = {
            $in: [stId, 0]
          }
        }
        if(this.ofcCompanys && this.ofcCompanys != 0 && this.ofcCompanys != '0') {
          where.cus_com_poi_ofc_cus_companys = this.ofcCompanys
        }
        if(this.searchKey) {
          where.company_name = {
            $likeboth: {
              concat: 'title',
              keyword: this.searchKey
            }
          }
        }
        if(this.orderType != 'all')  params.order_state = this.orderType
        if(this.sortType != '') params.order = this.sortType

        let param = {
          order: '-update_time',
          ...params,
          with: {
            include: [
              {
                table: 'cus_com_poi_ofc_cus_companys',
                keys: 'name'
              }
            ],
            relation: [
              {
                table: 'ofc_order_item',
                key: 'order_poi_ofc_order',
                keys: 'id, order_poi_ofc_order, thumbnail_url',
                limit: 1
              },
              {
                table: 'contract_relation',
                key: 'id_poi_ofc_order',
                needcount: 0,
                include: [
                  {
                    table: 'id_poi_contract',
                    relation: [
                      {
                        table: 'contract_files',
                        key: 'contract_poi_contract',
                        needcount: 0
                      }
                    ]
                  }
                ]
              },
              // {
              //   table: 'contract',
              //   key: 'order_poi_ofc_order',
              //   limit: 1,
              //   needcount: 0,
              //   relation: [
              //     {
              //       table: 'contract_files',
              //       key: 'contract_poi_contract',
              //       needcount: 0,
              //     }
              //   ]
              // }
            ]
          },
          where,
          keys: 'id,number,code,custom_number,company_name,receipt,tel,total_price,favour_price,discount_price,order_state,cus_com_poi_ofc_cus_companys'
        }
        let res = await this.$api.GetOfcOrder(param)
        this.list = this.list.concat(res.data.items.map((order) => {
          let thumb = ''
          if (order.order_rel_ofc_order_item.count > 0) {
            thumb = order.order_rel_ofc_order_item.items[0].thumbnail_url
          } else {
            thumb = 'error'
          }
          return {
            ...order,
            fullname: order.cus_com_poi_ofc_cus_companys ? order.cus_com_poi_ofc_cus_companys.fullname : '',
            tag: (order.order_state &&  order.order_state in this.flowStatus) ? this.flowStatus[order.order_state].alias : '',
            thumb
          }
        }))
        return res.data.items.length
      }catch(ex) {
        this.$toast(this.$error(ex))
        return 0
      }
    },
  }
}
</script>
